.wrapper {
    position: absolute;
    top: 0;
    bottom: 0;
    padding-left: 1rem;
    padding-right: 1rem;
    background: var(--tg-theme-secondary-bg-color);
    z-index: 10;
    width: 100%;
}

.left {
    left: -100%;
}

.openLeft {
    animation: openLeftAnim 0.2s ease-in-out;
    animation-fill-mode: forwards;
}

.closeLeft {
    animation: closeLeftAnim 0.2s ease-in-out;
    animation-fill-mode: forwards;
}

.header {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    margin-top: 15px;
}

.title {
    font-size: 20px;
    font-weight: 400;
}

@keyframes openLeftAnim {
    from {
        left: -100%;
        opacity: 0;
    }

    to {
        left: 0;
        opacity: 1;
    }
}

@keyframes closeLeftAnim {
    from {
        left: 0;
        opacity: 1;
    }

    to {
        left: -100%;
        opacity: 0;
    }
}