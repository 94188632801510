.wrapper {
    width: 100%;
}

.items {
    margin-top: 10px;
    border-radius: 6px;
    overflow-x: hidden;
    background: var(--tg-theme-section-bg-color);
    max-height: 250px;
    overflow-y: auto;
}

.item {
    padding: 10px 20px;
    cursor: pointer;
}

.light:hover {
    background: var(--tg-theme-hint-color);
}

.dark:hover {
    background: var(--tg-theme-hint-color);
}