.wrapper {
  display: flex;
  height: 100vh;
  width: 100vw;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: rgb(35, 46, 60);
}

.qr {
  width: 260px;
  margin-bottom: 30px;
}

.qr img {
  width: 100%;
}

.button {
  background: #5288c1;
  color: #fff;
  border-radius: 6px;
  padding: 10px 8px;
  cursor: pointer;
  min-width: 200px;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  text-decoration: none;
}