:root {
  --primary-color: #00A48B;
  --secondary-color: #00826f;
  --gray-color: #3A4756;
  --header-height: 45px;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  font-family: 'Open Sans', sans-serif;
  height: 100%;
  width: 100%;
  background: var(--tg-theme-secondary-bg-color);
  color: var(--tg-theme-text-color);
  overflow-x: hidden;
}

#root {
  overflow-x: hidden;
}

