.wrapper {
    width: 100%;
    border-bottom: 1px solid var(--tg-theme-section-separator-color);
    background: var(--tg-theme-section-bg-color);
    height: 48px;
}

.tabsWrapper {
    position: relative;
    display: flex;
    height: 100%;
}

.slider {
    position: absolute;
    height: 3px;
    border-radius: 3px 3px 0 0;
    background: var(--primary-color);
    bottom: 0;
    left: 0;
    transition: all 0.3s;
}

.tab {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--tg-theme-subtitle-text-color);
    cursor: pointer;
}

.tab.active {
    opacity: 1;
    color: var(--tg-theme-text-color);
}
