.switch {
    position: relative;
    display: inline-block;
    width: 46px;
    height: 24px;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #e8e8e8;
    transition: .3s;
    border-radius: 32px;
}

.slider:before {
    position: absolute;
    content: "";
    background: #fff;
    transition: .3s;
    border-radius: 50%;
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.15), 0 1px 1px rgba(0, 0, 0, 0.08);
    height: 20px;
    width: 20px;
    left: 2px;
    bottom: 2px;
}

.dark {
    background: var(--gray-color);
}

.dark:before {
    background: #617288;
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.15), 0 1px 1px rgba(0, 0, 0, 0.08);
}

.dark.checked {
    background: var(--gray-color) !important;
}

.slider.disabled.checked {
    background: #F8F8F8;
}

.slider.disabled:before {
    background: #E0E0E0;
}

.slider.checked {
    background: #e8e8e8;
}

.slider.checked:before {
    transform: translateX(22px);
    background: var(--primary-color);
}

.disabled {
    opacity: 0.4;
}