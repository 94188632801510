.caseTabs {
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    padding-bottom: 1rem;
}

.tab {
    width: 100%;
}

.tabContent {
    padding: 1rem;
}

.placeholder {
    font-weight: 300;
    font-size: 13px;
    color: var(--tg-theme-subtitle-text-color);
}

.list {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}