.wrapper {
    position: relative;
    overflow-y: hidden;
    min-height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    padding-top: var(--tg-safe-area-inset-top);
    padding-bottom: var(--tg-safe-area-inset-bottom);
    background: var(--tg-theme-secondary-bg-color);
}

.appTitle {
    position: absolute;
    width: 100%;
    padding-top: 6px;
    text-align: center;
    font-size: 1.5rem;
    font-weight: bold;
}

.appTitle span {
    color: var(--primary-color);
}

.content {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 30px;
    height: 100%;
    width: 100%;
    color: var(--tg-theme-text-color);
    flex: 1 0 auto;
    padding-left: 1rem;
    padding-right: 1rem;
    margin-bottom: 30px;
    padding-top: 1rem;
}

.menuItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1rem;
    font-weight: 400;
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid var(--tg-theme-section-separator-color);
}

.menuItem:nth-last-child(-n + 1) {
    border-bottom: 1px solid transparent;
}
