.wrapper {
    position: relative;
}

.input {
    padding: 10px 20px;
    background: var(--tg-theme-section-bg-color);
    border-radius: 6px;
    width: 100%;
    color: var(--tg-theme-text-color);
    border: 1px solid var(--tg-theme-secondary-bg-color);
    font-size: 16px;
    font-family: 'Open Sans', sans-serif;
}

.input:focus {
    border: 1px solid var(--tg-theme-hint-color);
    outline: none;
}

.error {
    border: 1px solid #ec5656;
}

.arrow {
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%) rotate(90deg);
    color: var(--tg-theme-hint-color);
}

.arrowFocused {
    transform: translateY(-50%) rotate(-90deg);
}