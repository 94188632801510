.wrapper {
  display: flex;
  border-bottom: 1px solid var(--tg-theme-section-separator-color);
  padding: 10px 0;
  cursor: pointer;
  overflow: hidden;

}

.wrapper:nth-last-child(-n + 1) {
  border-bottom: 1px solid transparent;
}

.inputWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.icon {
  align-self: flex-end;
}

.label {
  font-size: 12px;
  color: var(--tg-theme-subtitle-text-color);
}

.valueWrapper {
  width: 88%;
  overflow: hidden;
  white-space: nowrap;
}

.value {
  font-size: 16px;
  font-weight: 600;
  text-overflow: ellipsis;
  overflow: hidden;
}

.placeholder {
  font-size: 16px;
  font-weight: 300;
  flex: 1 0 auto;
  opacity: 0.7;
}