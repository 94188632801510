.appBar {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: calc(var(--tg-content-safe-area-inset-top) + 60px);
  width: 100%;
  background: var(--tg-theme-secondary-bg-color);
  z-index: 20;
}

.logo {
  width: 140px;
  height: 40px;
  opacity: 1;
  margin-bottom: 3px;
}

.appTitle {
  text-align: center;
  font-size: 1.25rem;
  font-weight: bold;
}

@keyframes logo {
  0% {
    opacity: 0;
  }
  85% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.appTitle span {
  color: var(--primary-color);
}