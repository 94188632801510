.wrapper {
    display: flex;
    flex-direction: column;
    background: var(--tg-theme-section-bg-color);
    border-radius: 15px;
    padding: 1rem;
}

.header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.row {
    display: flex;
    margin-bottom: 10px;
}

.column {
    display: flex;
    flex-direction: column;
}

.label {
    font-size: 13px;
}

.value {
    font-size: 16px;
    font-weight: 700;
}