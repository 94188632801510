.list {
    display: flex;
    flex-direction: column;
}

.item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;
    border-bottom: 1px solid var(--tg-theme-section-separator-color);
}

.item:nth-last-child(-n + 1) {
    border-bottom: 1px solid transparent;
}

.value {
    flex: 1;
}

.value p {
    font-weight: 600;
}

.courtLabel {
    display: block;
    font-size: 13px;
    width: 90%;
    text-wrap: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: var(--tg-theme-subtitle-text-color);
}

.icon {
    cursor: pointer;
}

.placeholder {
    display: block;
    font-size: 13px;
    font-weight: 300;
    color: var(--tg-theme-hint-color);
    text-align: center;
}

.hint {
    margin-top: 0.5rem;
    font-size: 13px;
    font-weight: 300;
    color: var(--tg-theme-hint-color);
    text-align: center;
}